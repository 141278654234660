<script>
import { required , sameAs } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      confirmPassword: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      location: null,
      isAuthError: false,
      processing : false,
      // role: null,
      // locations: [
      //   { text: "Choose Location ", value: null },
      //   "United State",
      //   "United Kingdom",
      // ],
      // roles: [
      //   { text: "Role ", value: null },
      //   "Super Admin",
      //   "Admin",
      //   "Shift Manager",
      //   "Employee",
      // ],
    };
  },
 
  validations: {
    confirmPassword: {
      required,
      sameAsPassword:sameAs('password')
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
  },
  methods: {
    // Try to log the user in with the username
    // and password they provided.
    changePassword() {
      let self = this;
      self.submitted = true;
      // stop here if form is invalid
      self.$v.$touch();

      if (self.$v.$invalid) {
        return;
      } else {
       
        if(self.processing === true){
          return false;

        }
        let url = window.location.href;
         url = new URL(url);
         var email = url.searchParams.get("email");
         var token = url.searchParams.get("token");
          
         let data = {
           "email": email,
           'token':token,
           "password":this.password
         }
           
           self.processing = true;
          self.$axios.post('/reset-password' , data)
        .then((response)=>{
            if(response.data.status==true){
                     self.triggerSwal(response.data.message, "success");
                     setInterval(function () {
                      location.href = '/login';

                     }, 1000);

            }
            else{
              console.log(response);
            }
            self.processing = false;
            
        }).catch(error => {
          self.triggerSwal(error, "success");
        self.processing = false;
            
            
        });   




      }
    },
  },
};
</script>

<template>
  <div>
    <div class="account-page">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-5">
            <div class="bg-white p-4 radius-20">
              <div class="inner-wrapper text-center">
                <div class="logo-wrapper mb-3">
                  <img src="@/assets/images/tipShare-logo.png" alt="logo" />
                </div>
                <div class="text-wrapper">
                  <p class="fw-bold roboto radius-30 text-uppercase">
                    Reset Password
                  </p>
                </div>
              </div>

              <b-alert
                v-model="isAuthError"
                variant="danger"
                class="mt-3"
                dismissible
                >{{ authError }}</b-alert
              >

              <div
                v-if="notification.message"
                :class="'alert ' + notification.type"
              >
                {{ notification.message }}
              </div>

              <b-form @submit.prevent="tryToLogIn">
                <!-- <b-form-group
                  id="input-group-1"
                  class="mb-3"
                  label="Email"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    v-model="email"
                    type="text"
                    class="form-control"
                    placeholder="Email address"
                    :class="{ 'is-invalid': submitted && $v.email.$error }"
                  ></b-form-input> -->
                  <!-- <div
                    v-if="submitted && $v.email.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.email.required">Email is required.</span>
                    <span v-if="!$v.email.email"
                      >Please enter valid email.</span
                    >
                  </div>
                </b-form-group> -->
                <!-- <b-form-group
                 
                  label="Location:"
                  label-for="input-3"
                  class="mb-3"
                >
                  <b-form-select
                    class="form-control py-2"
                    
                    v-model="location"
                    :options="locations"
                    required
                  ></b-form-select>
                </b-form-group> -->
                <!-- <b-form-group
                 
                  label="Role:"
                  label-for="input-3"
                  class="mb-3"
                >
                  <b-form-select
                    class="form-control py-2"
                    
                    v-model="role"
                    :options="roles"
                    required
                  ></b-form-select>
                </b-form-group> -->

                <b-form-group id="input-group-2" class="mb-4">
                  <label for="input-2">Password</label>
                  <b-form-input
                    id="input-2"
                    min="6"
                    v-model="password"
                    type="password"
                    placeholder="Password"
                    :class="{
                      'is-invalid': submitted && $v.password.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    Password is required.
                  </div>
                </b-form-group>
                <b-form-group id="input-group-2" class="mb-4">
                  <label for="input-2">Confirm Password</label>
                  <b-form-input
                    id="input-2"
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    :class="{
                      'is-invalid': submitted && $v.confirmPassword.$error,
                    }"
                  ></b-form-input>
                  <div
                    v-if="submitted && !$v.confirmPassword.required"
                    class="invalid-feedback"
                  >
                    Confirm Password is required.
                  </div>
                  <div
                    v-else-if="submitted && !$v.confirmPassword.sameAsPassword"
                    class="invalid-feedback"
                  >
                    Password is not matched with Confirm Password
                  </div>
                </b-form-group>

                <!-- <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="auth-remember-check"
                  /> -->
                  <!-- <label class="form-check-label" for="auth-remember-check"
                    >Remember me</label
                  > -->
                  <!-- <div class="float-end">
                    <router-link
                      to="/account/forgot-password"
                      class="text-muted forgot-password"
                      >Forgot password?</router-link
                    > 
                  </div>
                </div> -->
                <div class="mt-4 text-end">
                  <!-- <b-button type="submit"  v-on:click="tryingToLogIn" variant="primary" class="submit-btn w-100 gradientColor border-0 radius-30 py-2 fw-bold"
                    >
                    
                    <template v-if="processing" >
                     <loader />
                    </template>
                    <template v-else>Login</template>
                    
                    
                    
                    </b-button -->
                    
                  <SubmitButton :processing="processing" text="Change Passwprd" @clicked="changePassword" />
                </div>
                <!-- <div class="mt-4 text-center">
                  <p class="mb-0 dont-account">
                    Don't have an account ?
                    <router-link to="/register" class=" primaryColor fw-bold roboto">Signup now</router-link>
                  </p>
                </div> -->
                <!-- <div class="mt-3 text-center">
                      <div class="signin-other-title">
                        <h5 class=" mb-4 title">OR <span>Login</span> with</h5>
                      </div>

                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                          >
                            <img src="@/assets/images/twitter-icon.svg" />
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                          >
                            <img src="@/assets/images/gmail-icon.svg" />
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                          >
                            <img src="@/assets/images/facebook-icon.svg" />
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                          >
                            <img src="@/assets/images/apple-icon.svg" />
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                          >
                            <img src="@/assets/images/window-icon.svg" />
                          </a>
                        </li>
                      </ul>
                    </div> -->
              </b-form>

              <!-- end card-body -->
            </div>
          </div>
          <!-- end card -->

          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" module></style>
